

function App() {
 

  return (
    

    [<theme-toggle id="wc1"></theme-toggle>,

      <div className="app-container" >
        
<boss-card className="glassmorph-card" id="wc2" style={{display: "grid",
"--media-area": "1 / 1 / 2 / 2",
"--content-area": "1 / 1 / 2 / 2",
zIndex: "4",
position: "relative",
fontFamily:"font-family: 'Oswald', sans-serif"
}}>
<div slot="card-content" className="glassmorph-card" >

  <h1 slot="title" style={{fontFamily: "'Oswald', sans-serif"}}>Hi... <span >
          It's been awhile since I've updated the site... 
          </span></h1>
          <br/>
          <h2 slot="content" style={{overflowY: "hidden",fontFamily: "Oswald, sans-serif"}}>A NEW ONE IS BEING DEVELOPED, AND WILL BE AVAILABLE SOON!</h2>
   </div>
       
     
          </boss-card>




       



      </div>]


     
    
  );
}

export default App;
